import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

const styles = {
  SecuriumLogo: {
    height: "5vh",
  },
};

function SecuriumLogo(props) {
  const { classes } = props;

  return (
    <img
      className={classes.SecuriumLogo}
      src="/assets/logos/Large_logo_for_light_bg.png"
      alt="Logo"
    />
  );
}

SecuriumLogo.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SecuriumLogo);
