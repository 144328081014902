import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";

import Nav from "./landingPage/Nav.js";
import Footer from "./landingPage/Footer.js";

const useStyles = makeStyles((theme) => ({
  privacyContainer: {
    width: "90%",
    margin: "auto",
  },
  h2: {
    fontSize: "1.4rem",
  },
  subheading: {
    fontSize: "1rem",
    marginTop: "0.7vh",
  },
  p: {
    fontSize: "0.8rem",
    marginTop: "0.2vh",
    textAlign: "justify",
  },
}));

function Privacy(props) {
  const classes = useStyles();
  return (
    <div>
      <Nav></Nav>
      <Container maxWidth="false" className={classes.privacyContainer}>
        <Typography variant="h3" style={{ fontSize: "3.8vh" }}>
          <b>Privacy Policy</b>
        </Typography>
      </Container>
      <br />
      <Container maxWidth="false" className={classes.privacyContainer}>
        <Typography variant="h2" className={classes.h2}>
          <b>Background:</b>
        </Typography>
        <Typography variant="body1" className={classes.p}>
          Securium Ltd understands that your privacy is important to you and
          that you care about how your personal data is used. We respect and
          value the privacy of everyone who visits this website, Securium.co.uk
          (“Our Site”) and only collect and use your personal data as described
          in this Privacy Policy. Any personal data we collect will only be used
          as permitted by law.
        </Typography>
        <Typography variant="body1" className={classes.p}>
          Please read this Privacy Policy carefully and ensure that you
          understand it.
        </Typography>
        <ol className={classes.policyList}>
          <li>
            <Typography variant="body1" className={classes.subheading}>
              <b>Information About Us</b>
            </Typography>
            <Typography variant="body1" className={classes.p}>
              Our Site is operated by Securium Ltd registered in England under
              company number 10087459. Registered address: The White House, 2
              Meadrow, Godalming, Surrey, United Kingdom, GU7 3HN
            </Typography>
          </li>
          <li>
            <Typography variant="body1" className={classes.subheading}>
              <b>What Does This Policy Cover?</b>
            </Typography>
            <Typography variant="body1" className={classes.p}>
              This Privacy Policy applies only to your use of Our Site. Our Site
              may contain links to other websites. Please note that we have no
              control over how your data is collected, stored, or used by other
              websites and we advise you to check the privacy policies of any
              such websites before providing any data to them.
            </Typography>
          </li>
          <li>
            <Typography variant="body1" className={classes.subheading}>
              <b>What Is Personal Data?</b>
            </Typography>
            <Typography variant="body1" className={classes.p}>
              Personal data is defined by the UK GDPR and the Data Protection
              Act 2018 (collectively, “the Data Protection Legislation”) as ‘any
              information relating to an identifiable person who can be directly
              or indirectly identified in particular by reference to an
              identifier’.
            </Typography>
            <Typography variant="body1" className={classes.p}>
              Personal data is, in simpler terms, any information about you that
              enables you to be identified. Personal data covers obvious
              information such as your name and contact details, but it also
              covers less obvious information such as identification numbers,
              electronic location data, and other online identifiers.
            </Typography>
          </li>
          <li>
            <Typography variant="body1" className={classes.subheading}>
              <b>What Are My Rights?</b>
            </Typography>
            <Typography variant="body1" className={classes.p}>
              Under the Data Protection Legislation, you have the following
              rights, which we will always work to uphold:
              <ol type="a">
                <li>
                  The right to be informed about our collection and use of your
                  personal data. This Privacy Policy should tell you everything
                  you need to know, but you can always contact us to find out
                  more or to ask any questions using the details in Part 10.
                </li>
                <li>
                  The right to access the personal data we hold about you. Part
                  9 will tell you how to do this.
                </li>
                <li>
                  The right to have your personal data rectified if any of your
                  personal data held by us is inaccurate or incomplete. Please
                  contact us using the details in Part 10 to find out more.
                </li>
                <li>
                  The right to be forgotten, i.e. the right to ask us to delete
                  or otherwise dispose of any of your personal data that we
                  hold. Please contact us using the details in Part 10 to find
                  out more.
                </li>
                <li>
                  The right to restrict (i.e. prevent) the processing of your
                  personal data.
                </li>
                <li>
                  The right to object to us using your personal data for a
                  particular purpose or purposes.
                </li>
                <li>
                  The right to withdraw consent. This means that, if we are
                  relying on your consent as the legal basis for using your
                  personal data, you are free to withdraw that consent at any
                  time.
                </li>
                <li>
                  The right to data portability. This means that, if you have
                  provided personal data to us directly, we are using it with
                  your consent or for the performance of a contract, and that
                  data is processed using automated means, you can ask us for a
                  copy of that personal data to re-use with another service or
                  business in many cases.
                </li>
                <li>
                  Rights relating to automated decision-making and profiling. We
                  do not use your personal data in this way.
                </li>
              </ol>
            </Typography>
            <Typography variant="body1" className={classes.p}>
              For more information about our use of your personal data or
              exercising your rights as outlined above, please contact us using
              the details provided in Part 10.
            </Typography>
            <Typography variant="body1" className={classes.p}>
              It is important that your personal data is kept accurate and
              up-to-date. If any of the personal data we hold about you changes,
              please keep us informed as long as we have that data.
            </Typography>
            <Typography variant="body1" className={classes.p}>
              Further information about your rights can also be obtained from
              the Information Commissioner’s Office or your local Citizens
              Advice Bureau.
            </Typography>
            <Typography variant="body1" className={classes.p}>
              If you have any cause for complaint about our use of your personal
              data, you have the right to lodge a complaint with the Information
              Commissioner’s Office. We would welcome the opportunity to resolve
              your concerns ourselves, however, so please contact us first,
              using the details in Part 10.
            </Typography>
          </li>
          <li>
            <Typography variant="body1" className={classes.subheading}>
              <b>What Personal Data Do You Collect and How?</b>
            </Typography>
            <Typography variant="body1" className={classes.p}>
              Subject to the following, we do not collect any personal data from
              you. We do not place cookies on your computer or device, nor do we
              use any other means of data collection.
            </Typography>
            <Typography variant="body1" className={classes.p}>
              Our Site collects certain information automatically, including
              your IP address, the type of browser you are using, and certain
              other non-personal data about your computer or device such as your
              operating system type or version, and display resolution.
            </Typography>
            <Typography variant="body1" className={classes.p}>
              <b>
                If you send us an email, we may collect your full name, your
                email address, and any other information which you choose to
                give us. For the purposes of the Data Protection Legislation, we
                are the data controller responsible for such personal data.
              </b>
            </Typography>
            <Typography variant="body1" className={classes.p}>
              The lawful basis under the Data Protection Legislation that allows
              us to use such information is article 6(1)(f) of the UK GDPR which
              allows us to process personal data when it is necessary for the
              purposes of our legitimate interests, in this case, the proper
              operation and functionality of Our Site. If you contact us as
              described above, you will be required to consent to our use of
              your personal data to contact you. In this case, our lawful basis
              for using your personal data will be article 6(1)(a) of the UK
              GDPR, which allows us to use your personal data with your consent
              for a particular purpose or purposes.
            </Typography>
          </li>
          <li>
            <Typography variant="body1" className={classes.subheading}>
              <b>How Do You Use My Personal Data?</b>
            </Typography>
            <Typography variant="body1" className={classes.p}>
              Where we collect any personal data, it will be processed and
              stored securely, for no longer than is necessary in light of the
              reason(s) for which it was first collected. We will comply with
              our obligations and safeguard your rights under the Data
              Protection Legislation at all times. For more details on security
              see Part 7, below.
            </Typography>
            <Typography variant="body1" className={classes.p}>
              As stated above, we do not generally collect any personal data
              directly from you, but if you contact us and we obtain your
              personal details from your email, we may use them to respond to
              your email. The other technical data referred to above is
              necessary for the technical operation of Our Site and will not
              normally be used in any way to personally identify you.
            </Typography>
            <Typography variant="body1" className={classes.p}>
              Personal data that we process for any purpose or purposes shall
              not be kept for longer than is necessary for that purpose or those
              purposes.We will not share any of your personal data with any
              third parties for any purposes other than storage on an email
              and/or web hosting server.
            </Typography>
          </li>
          <li>
            <Typography variant="body1" className={classes.subheading}>
              <b>How and Where Do You Store My Data?</b>
            </Typography>
            <Typography variant="body1" className={classes.p}>
              We will take appropriate technical and organisational precautions
              to secure your personal data and to prevent the loss, misuse or
              alteration of your personal data.
            </Typography>
            <Typography variant="body1" className={classes.p}>
              We will store all your personal data on secure servers, personal
              computers and mobile devices, and in secure manual record-keeping
              systems.
            </Typography>
          </li>
          <li>
            <Typography variant="body1" className={classes.subheading}>
              <b>Do You Share My Personal Data?</b>
            </Typography>
            <Typography variant="body1" className={classes.p}>
              We will not share any of your personal data with any third parties
              for any purposes, subject to the following exception.
            </Typography>
            <Typography variant="body1" className={classes.p}>
              If we sell, transfer, or merge parts of our business or assets,
              your personal data may be transferred to a third party. Any new
              owner of our business may continue to use your personal data in
              the same way that we have used it, as specified in this Privacy
              Policy (i.e. to communicate with you).
            </Typography>
            <Typography variant="body1" className={classes.p}>
              In some limited circumstances, we may be legally required to share
              certain personal data, which might include yours, if we are
              involved in legal proceedings or complying with legal obligations,
              a court order, or the instructions of a government authority.
            </Typography>
          </li>
          <li>
            <Typography variant="body1" className={classes.subheading}>
              <b>How Can I Access My Personal Data?</b>
            </Typography>
            <Typography variant="body1" className={classes.p}>
              If you want to know what personal data we have about you, you can
              ask us for details of that personal data and for a copy of it
              (where any such personal data is held). This is known as a
              “subject access request”.
            </Typography>
            <Typography variant="body1" className={classes.p}>
              All subject access requests should be made in writing and sent to
              the email or postal addresses shown in Part 10.
            </Typography>
            <Typography variant="body1" className={classes.p}>
              There is not normally any charge for a subject access request. If
              your request is ‘manifestly unfounded or excessive’ (for example,
              if you make repetitive requests) a fee may be charged to cover our
              administrative costs in responding.
            </Typography>
            <Typography variant="body1" className={classes.p}>
              We will respond to your subject access request within 1 one month
              of receiving it. Normally, we aim to provide a complete response,
              including a copy of your personal data within that time. In some
              cases, however, particularly if your request is more complex, more
              time may be required up to a maximum of three months from the date
              we receive your request. You will be kept fully informed of our
              progress.
            </Typography>
          </li>
          <li>
            <Typography variant="body1" className={classes.subheading}>
              <b>How Do I Contact You?</b>
            </Typography>
            <Typography variant="body1" className={classes.p}>
              Email address:{" "}
              <a href="mailto:info@securium.co.uk">info@securium.co.uk</a>
            </Typography>
          </li>
          <li>
            <Typography variant="body1" className={classes.subheading}>
              <b>Changes to this Privacy Policy</b>
            </Typography>
            <Typography variant="body1" className={classes.p}>
              We may change this Privacy Notice from time to time. This may be
              necessary, for example, if the law changes, or if we change our
              business in a way that affects personal data protection.
            </Typography>
            <Typography variant="body1" className={classes.p}>
              Any changes will be immediately posted on Our Site and you will be
              deemed to have accepted the terms of the Privacy Policy on your
              first use of Our Site following the alterations. We recommend that
              you check this page regularly to keep up-to-date. This Privacy
              Policy was last updated on July 2021.
            </Typography>
          </li>
        </ol>
      </Container>
      <Footer></Footer>
    </div>
  );
}

export default Privacy;
