import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Button from "@material-ui/core/Button";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import Nav from "./landingPage/Nav.js";
import Footer from "./landingPage/Footer.js";

const useStyles = makeStyles((theme) => ({
  page: {
    minHeight: "40vh",
  },
}));

function Careers(props) {
  const classes = useStyles();
  return (
    <div>
      <Nav></Nav>
      <Container maxWidth="lg">
        <Typography variant="h2" style={{ fontSize: "3rem" }}>
          Careers
        </Typography>
      </Container>
      <br />
      <Container maxWidth="lg" className={classes.page}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">
              Business Strategy and Development Manager
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Container maxWidth="md">
              <b>Job Role:</b> Business Strategy and Development Manager <br />
              <b>Job type:</b> Full-time/Part-time <br />
              <b>Location:</b> UK, Flexible <br />
              <br />
              <b>The company:</b> Securium is a fast-growing technology company
              with a focus on developing secure & data-driven advanced
              cyber-intelligence software & services to protect businesses &
              individuals. Securium’s innovations act to detect and prevent
              online harm from grooming and coercion, and to gather targeted
              intelligence about content intended to incite. Securium’s products
              can also be applied in areas such as brand reputation management
              and for the evaluation of fake news and misinformation. Securium
              is a founding member of the Online Safety Technology Industry
              Association (OSTIA), which seeks to ensure that policymakers and
              big companies have visibility of innovations, new technology and
              best practice for online safety and to promote the uptake of such
              technologies, resulting in a safer internet.
              <br />
              <br />
              <b>The role:</b> We are now seeking to accelerate our growth by
              hiring a Business Strategy and Development Manager with a proven
              track record of revenue growth to join our high-performing team in
              making a difference in the fight against online harm. You will be
              working closely with the founders of the company and will engage
              extensively across Securium’s global customer segments which cover
              government and law enforcement agencies, communication protection
              organisations and forensic analysis companies, ISP’s, social
              media/gaming and streaming platforms, chatroom moderators, school
              monitoring organisations, reputation management, revenue
              protection and fact-checking companies.
              <br />
              <br />
              This role would be ideal for someone who is proactive with a
              willingness to learn and adapt, and has a delivery mindset.
              Someone with analytical and creative thinking that can find
              solutions that balance the client’s needs against costs, schedules
              and risks.
              <br />
              <br />
              <b>You will:</b>
              <br />
              <ul>
                <li>
                  Define, forecast, and build a pipeline of new business
                  opportunities by understanding customers’ and markets’ needs
                  and applying tailored approaches to address them.
                </li>
                <li>
                  Define and execute sales and marketing strategy, establish
                  strategic and tactical plans required to identify, qualify,
                  and convert opportunities.
                </li>
                <li>
                  Be part of the leadership team to secure future investment and
                  strategic partnerships.
                </li>
                <li>
                  Drive new product and/or feature adoption in support of your
                  engagement with existing and prospective customers.
                </li>
                <li>
                  Support investment planning and assisting the founders with
                  the management of investor relations.
                </li>
                <li>
                  Be part of contract negotiation, drafting and management.
                </li>
                <li>
                  Be aware of the changes in legislation, technologies, and
                  trends in the online safety sector.
                </li>
                <li>Understand and own key business deliverables/KPI’s.</li>
                <li>
                  Maintain a CRM database reflecting the progress and plans for
                  new business.
                </li>
                <li>
                  Support the business through other activities integral to such
                  a role.
                </li>
              </ul>
              <b>About you:</b>
              <br />
              <ul>
                <li>UK/Irish Citizen or a valid UK working visa.</li>
                <li>
                  Proven track record of developing new business and selling to
                  enterprises within relevant sectors, and meeting/exceeding
                  revenue targets.
                </li>
                <li>
                  Excellent customer relations skills and can quickly understand
                  and comfortably communicate about disruptive new products and
                  service offerings.
                </li>
                <li>
                  Excellent prospecting, networking, negotiation, leading and
                  closing skills with customers, new businesses, partners, and
                  investors alike.
                </li>
              </ul>
              A team player but with the drive, ability, and confidence to lead
              business strategy and development globally.
              <br />
              <br />
              <b>Our offering:</b>
              <br />
              We believe in equal opportunities, diversity and inclusion which
              is reflected throughout the company and the founding team (ask us
              about it). We have flexibility regarding working location. Our
              Head Office is located on the Guildford Business Park, and we can
              support working remotely as well as at our shared working space in
              London (Piccadilly), in the prestigious offices of Telefonica/O2,
              our partners.
              <ul>
                <li>Flexible working hours and lcoations</li>
                <li>Competitive salary with career progression</li>
                <li>Generous commission</li>
                <li>Pension</li>
                <li>
                  Share option scheme and private health insurance are planned
                </li>
                <li>
                  25 days Annual Leave plus 8 Bank Holidays, increasing with
                  length of service
                </li>
                <li>
                  At Head Office: free on-site parking and convenient access to
                  the A3; free shuttle buses from Guildford train station; free
                  wellbeing activities, including Pilates classes, and free hot
                  drinks available (London also).
                </li>
              </ul>
              <br />
              To apply, please email your CV and a few lines on why you think
              you are suitable for this position to anna@securium.co.uk (Direct
              applications only)
              <br />
              <br />
              <Button
                href="mailto:anna@securium.co.uk"
                variant="contained"
                color="primary"
              >
                Apply
              </Button>
            </Container>
          </AccordionDetails>
        </Accordion>
      </Container>
      <Footer></Footer>
    </div>
  );
}

export default Careers;
