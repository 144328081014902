import React from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

import Support from "./Support.js";
import { isMobile } from "react-device-detect";

const styles = {
  home: {
    width: "100%",
  },
  content: {
    backgroundImage: "url('/assets/images/background-new-ideas.jpg')",
    backgroundRepeat: "repeat",
    backgroundSize: "100% 100%",
    width: "100%",
    height: "72vh",
    paddingTop: "2vh",
    paddingBottom: "1vh",
    textAlign: "left",
  },
  contentDesktopL1: {
    width: "100%",
    height: "100%",
    overflow: "visible",
    position: "relative",
    margin: "auto",
  },
  contentDesktopL2: {
    width: "90%",
    height: "100%",
    position: "relative",
    margin: "auto",
  },
  mobileContent: {
    backgroundImage: "url('/assets/images/background_solid_colour.jpeg')",
    backgroundRepeat: "repeat",
    backgroundSize: "100% 100%",
    paddingTop: "5vh",
    paddingLeft: "10%",
    paddingRight: "10%",
    display: "flex",
    width: "80%",
    textAlign: "left",
    overflow: "hidden",
  },
  h: {
    paddingBottom: "5vh",
    fontSize: "6vh",
    color: "white",
  },
  p: {
    paddingBottom: "1vh",
    fontSize: "2.8vh",
    color: "black",
  },
  whitep: {
    paddingBottom: "10px",
    fontSize: "3.8vh",
    color: "white",
  },
  gridContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    width: "54.7%",
    marginRight: "auto",
  },
};

function Home(props) {
  const { classes } = props;

  if (isMobile) {
    return (
      <div>
        <div className={classes.home}>
          <div className={classes.screen}>{props.children}</div>
          <div className={classes.mobileContent}>
            <Grid container>
              <Grid item>
                <Typography variant="h3" className={classes.h}>
                  <b>Innovative Systems for a Safer Internet</b>
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body2" className={classes.p}>
                  Advanced Cyber-Intelligence company developing software and
                  services to protect businesses and individuals.
                </Typography>
                <Typography variant="body1" className={classes.p}>
                  Through sophisticated content and conversation analysis, we
                  provide high value targeted intelligence that helps our
                  customers protect what is most valuable for them online.
                </Typography>
              </Grid>
            </Grid>
          </div>
        </div>
        <Support></Support>
      </div>
    );
  } else {
    return (
      <div>
        <div className={classes.home}>
          <div className={classes.screen}>{props.children}</div>
          <div className={classes.content}>
            <div className={classes.contentDesktopL1}>
              <div className={classes.contentDesktopL2}>
                <div className={classes.gridContainer}>
                  <Grid container alignItems="left" justify="left">
                    <Grid item>
                      <Typography variant="h3" className={classes.h}>
                        <b>Innovative Systems for a Safer Internet</b>
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="body2" className={classes.p}>
                        Advanced Cyber-Intelligence company developing software
                        and services to protect businesses and individuals.
                      </Typography>
                      <Typography variant="body1" className={classes.p}>
                        Through sophisticated content and conversation analysis,
                        we provide high value targeted intelligence to help our
                        customers protect what is most valuable for them online.
                      </Typography>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Support></Support>
      </div>
    );
  }
}

Home.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Home);
