import React from "react";
import PropTypes from "prop-types";
// import Typography from '@material-ui/core/Typography';
import { withStyles } from "@material-ui/core/styles";

import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";

import { isMobile, MobileView } from "react-device-detect";

import ContactUs from "./ContactUs.js";

import { ReactComponent as LinkedIn } from "../assets/icons/LinkedIn.svg";
import { ReactComponent as Twitter } from "../assets/icons/Twitter.svg";

import { TwitterTimelineEmbed } from "react-twitter-embed";

// import { Timeline } from "react-twitter-widgets";

const styles = {
  footer: {
    width: "100%",
    marginTop: "2vh",
    paddingTop: "2vh",
    //   backgroundColor: '#263238',
    backgroundColor: "rgb(68, 68, 68)",
    paddingBottom: "5vh",
    "& *": {
      color: "rgba(255, 255, 255, 0.7)",
      borderColor: "rgba(255, 255, 255, 0.7)",
    },
  },
  mobileH: {
    paddingBottom: "15px",
    fontSize: "34px",
    textAlign: "center",
  },
  gridContainer: {
    width: "90%",
    margin: "auto",
  },
  h: {},
  p: {
    paddingBottom: "10px",
    fontSize: "20px",
  },
  grow: {
    float: "right",
  },
  icon: {
    paddingLeft: "5px",
    paddingRight: "5px",
    width: "40px",
  },
  privacy: {
    textAlign: "right",
  },
  copyright: {
    textAlign: "right",
  },
  privacyLink: {
    color: "rgba(255, 255, 255, 0.7)",
    textDecoration: "none",
  },
  link: {
    display: "inline-block",
    height: "100%",
  },
  socialsGrid: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    marginBottom: "2vh",
  },
};

function Footer(props) {
  const { classes } = props;
  var values = {};
  if (isMobile) {
    values.direction = "column";
    values.xs1 = 12;
    values.xs2 = 12;
  } else {
    values.direction = "row";
    values.xs1 = 3;
    values.xs2 = 6;
    values.xs3 = 2;
  }

  return (
    <div className={classes.footer}>
      <Grid
        container
        className={classes.gridContainer}
        direction={values.direction}
      >
        <Grid item xs={values.xs2} style={{ paddingRight: "1%" }}>
          <ContactUs></ContactUs>
        </Grid>
        <MobileView>
          <br></br>
          <br></br>
        </MobileView>
        <Grid item xs={values.xs2}>
          <Grid container style={{ height: 400 }}>
            <Grid item xs={12} style={{ paddingLeft: "1%" }}>
              <Grid container direction="row" className={classes.socialsGrid}>
                <Grid item xs={4}>
                  <Typography
                    variant="h3"
                    style={{
                      fontSize: "3.8vh",
                      margin: "auto",
                      justify: "center",
                    }}
                    className={`banner ${
                      isMobile ? classes.mobileH : classes.h
                    }`}
                  >
                    <b>Social Media</b>
                  </Typography>
                </Grid>
                <Grid item xs={8} justify="center">
                  <Link
                    href="https://twitter.com/securiumltd"
                    target="_blank"
                    className={classes.link}
                  >
                    <Twitter className={classes.icon}></Twitter>
                  </Link>
                  <Link
                    href="https://pk.linkedin.com/company/securiumltd?trk=similar-companies_org_image"
                    target="_blank"
                    className={classes.link}
                  >
                    <LinkedIn className={classes.icon}></LinkedIn>
                  </Link>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container direction="row">
            <Grid item xs={10} className={classes.privacy}>
              <a className={classes.privacyLink} href="/privacy">
                Privacy Policy
              </a>
            </Grid>
            <Grid item xs={2}>
              <div className={classes.grow}>
                &copy; 2019 - 2023, Securium LTD
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

Footer.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Footer);
