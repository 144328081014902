import React from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import Break from "./Break.js";

import aboutUsTeam from "./../assets/images/WeAreSecurium-V3.png";

const styles = {
  about: {
    width: "100%",
    paddingTop: "2vh",
  },
  mobileHeader: {
    width: "100%",
    marginBottom: "4vh",
    textAlign: "center",
  },
  header: {
    width: "100%",
    marginBottom: "4vh",
  },
  p: {
    marginBottom: "20px",
    fontSize: "1rem",
    textAlign: "justify",
  },
  gridContainer: {
    padding: "1vh",
    marginBottom: "1vh",
    display: "flex",
    justifyContent: "center",
    margin: "auto",
    width: "90%",
  },
  imgContainer: {
    display: "flex",
    justifyContent: "center",
    height: "100%",
  },
  img: {
    width: "90%",
    justify: "center",
    margin: "auto",
    display: "block",
  },
  textContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    height: "100%",
  },
};

function AboutUs(props) {
  const { classes } = props;

  const isMobile = window.innerWidth < 1366;
  var values = {};
  values.pad = {};
  if (isMobile) {
    values.pad.side = false;
    values.pad.inner = 12;
  } else {
    values.pad.inner = 6;
  }

  return (
    <div id="about-us" className={classes.about}>
      <Grid
        container
        className={classes.gridContainer}
        spacing={0}
        direction="row"
      >
        <Grid item xs={values.pad.inner}>
          <div className={classes.textContainer}>
            <div
              className={`banner ${
                isMobile ? classes.mobileHeader : classes.header
              }`}
            >
              <Typography variant="h3" style={{ fontSize: "3.8vh" }}>
                <b>About Us</b>
              </Typography>
            </div>
            <Typography variant="body1" className={classes.p}>
              <b>We are Securium</b>, a cyber intelligence company providing
              online safety technologies to protect businesses and vulnerable
              individuals online.
            </Typography>
            <Typography variant="body1" className={classes.p}>
              Our products are innovative in their focus on acting to prevent
              online harm, in contrast solely to detecting that it has happened
              or continues to happen. They are variously grounded in
              psychological theories, and other scientific literature, to
              evaluate – for example - the emergence of grooming and coercion,
              and to gather targeted intelligence about content intended to
              incite.
            </Typography>
            <Typography variant="body1" className={classes.p}>
              We provide fast, automatic and cost-effective software tools for
              conversation and content analysis, to extract rapid insight from
              massive-scale and mixed data to allow clients to focus on
              identifying those who can do great harm, whilst reducing their
              exposure to such materials.
            </Typography>
            <Typography variant="body1" className={classes.p}>
              <b>Our mission</b> is to create safer and stronger communities by
              safeguarding businesses and individuals, especially children,
              online.
            </Typography>
          </div>
        </Grid>
        <Grid item xs={values.pad.inner}>
          <div className={classes.imgContainer}>
            <img
              className={classes.img}
              src={aboutUsTeam}
              alt="Securium Team"
            ></img>
          </div>
        </Grid>
      </Grid>
      <Break height="50vh"></Break>
    </div>
  );
}

AboutUs.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AboutUs);
