import React from "react";
import PropTypes from "prop-types";
// import Typography from '@material-ui/core/Typography';
import { withStyles } from "@material-ui/core/styles";
import Slider from "react-slick";
import Break from "./Break.js";

import wayra from "../assets/logos/wayra.png";
import oxfordInnovation from "../assets/logos/oxford-innovation.png";
import homeOffice from "../assets/logos/home-office.png";
import innovateUK from "../assets/logos/innovate-uk-logo.jpg";
import DASA from "../assets/logos/DASA.jpg";
import O2 from "../assets/logos/O2.png";
import setsquared from "../assets/logos/setsquared.svg";
import telefonica from "../assets/logos/telefonica.jpg";
import NCA from "../assets/logos/NCA.png";
import metPolice from "../assets/logos/met-police.jpg";
import ostia from "../assets/logos/ostia.png";
import EU from "../assets/logos/EU.png";
import CEOP from "../assets/logos/CEOP.png";

const styles = {
  Support: {
    width: "100%",
    overflow: "hidden",
    // backgroundImage: "url('/assets/images/background_02.jpg')",
    //   backgroundSize: "cover",
    // backgroundRepeat: "no-repeat",
    // backgroundSize: "100vw 100vh",
  },
  image: {
    height: "8vh",
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
  },
  content: {
    height: "8vh",
    padding: "1.5vh",
  },
  sliderItem: {
    textAlign: "center",
  },
};

function Support(props) {
  const { classes } = props;

  var n = 4;
  if (window.innerWidth < 500) {
    n = 1;
  } else if (window.innerWidth < 900) {
    n = 2;
  } else if (window.innerWidth < 1366) {
    n = 3;
  }

  return (
    <div className={classes.Support}>
      <div className={classes.content}>
        <Slider
          slidesToShow={n}
          slidesToScroll={1}
          autoplay
          autoplaySpeed={2000}
        >
          <div className={classes.sliderItem}>
            <img
              className={classes.image}
              alt="Home office logo"
              src={homeOffice}
            ></img>
          </div>
          <div>
            <img className={classes.image} alt="Wayra logo" src={wayra}></img>
          </div>
          <div>
            <img
              className={classes.image}
              alt="Innovate UK logo"
              src={innovateUK}
            ></img>
          </div>
          <div>
            <img
              className={classes.image}
              alt="Oxford Innovation logo"
              src={oxfordInnovation}
            ></img>
          </div>
          <div>
            <img className={classes.image} alt="DASA logo" src={DASA}></img>
          </div>
          <div>
            <img className={classes.image} alt="NCA logo" src={NCA}></img>
          </div>
          <div>
            <img className={classes.image} alt="O2 logo" src={O2}></img>
          </div>
          <div>
            <img
              className={classes.image}
              alt="Set Squared logo"
              src={setsquared}
            ></img>
          </div>
          <div>
            <img
              className={classes.image}
              alt="Telefonica logo"
              src={telefonica}
            ></img>
          </div>
          <div>
            <img
              className={classes.image}
              alt="metropolitan police logo"
              src={metPolice}
            ></img>
          </div>
          <div>
            <img className={classes.image} alt="Ostia logo" src={ostia}></img>
          </div>
          <div>
            <img className={classes.image} alt="EU ERDF logo" src={EU}></img>
          </div>
          <div>
            <img className={classes.image} alt="CEOP logo" src={CEOP}></img>
          </div>
        </Slider>
      </div>
      <Break></Break>
    </div>
  );
}

Support.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Support);
