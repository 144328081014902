import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

const styles = {
  break: {
    width: "100%",
    height: "2vh",
    backgroundColor: "rgb(51, 204, 204)",
  },
};

function Break(props) {
  const { classes } = props;
  return <div className={classes.break}></div>;
}

Break.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Break);
