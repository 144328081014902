import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import HomePage from "./HomePage";
import Careers from "./Careers";
import Privacy from "./Privacy";
import PressPage from "./PressPage";

import "./App.css";
import { isMobile } from "react-device-detect";

const useStyles = makeStyles((theme) => ({
  app: {
    width: "100vw",
    overflow: "hidden",
  },
}));

function App(props) {
  const classes = useStyles();
  const [type, setType] = useState(0);

  const update = useEffect(() => {
    setType(1);
  }, [isMobile]);

  return (
    <div className={classes.app}>
      <Router>
        <Switch>
          <Route exact path="/press">
            <PressPage />
          </Route>
          <Route exact path="/careers">
            <Careers />
          </Route>
          <Route exact path="/privacy">
            <Privacy />
          </Route>
          <Route path="/">
            <HomePage />
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
