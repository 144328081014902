import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import Drawer from "@material-ui/core/Drawer";

import SecuriumLogo from "./Logos.js";
import menuSVG from "../assets/icons/menu.svg";

import { BrowserView, MobileView } from "react-device-detect";

const styles = {
  root: {
    flexGrow: 1,
  },
  grow: {
    flexGrow: 1,
  },
  nav: {
    boxShadow: "none",
    backgroundColor: "white",
    height: "10vh",
  },
  img: {
    height: "3vh",
  },
};

function Nav(props) {
  const { classes } = props;

  const [state, setState] = React.useState({
    top: false,
  });

  const toggleDrawer = (side, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [side]: open });
  };

  return (
    <div className={classes.root}>
      <AppBar className={classes.nav} position="sticky" color="default">
        <Toolbar>
          <SecuriumLogo className={classes.logo}></SecuriumLogo>
          <div className={classes.grow} />
          <BrowserView>
            <Button href="/#">Home</Button>
            <Button href="/#about-us">About Us</Button>
            <Button href="/#products">Products</Button>
            {/*<Button href="/press">Press</Button>*/}
            {/*<Button href="/careers">Careers</Button>*/}
            <Button href="#contact-us">Contact Us</Button>
            {/* <Button disabled >Dashboard</Button> */}
          </BrowserView>
          <MobileView>
            <Button onClick={toggleDrawer("top", true)}>
              <img alt="Menu" className={classes.img} src={menuSVG}></img>
            </Button>
            <Drawer
              anchor="top"
              open={state.top}
              onClose={toggleDrawer("top", false)}
            >
              <Button onClick={toggleDrawer("top", false)} href="/#">
                Home
              </Button>
              <Button onClick={toggleDrawer("top", false)} href="/#products">
                Products
              </Button>
              <Button onClick={toggleDrawer("top", false)} href="/#about-us">
                About Us
              </Button>
              {/*<Button onClick={toggleDrawer("top", false)} href="/#press">
                Press
              </Button>*/}
              {/*<Button onClick={toggleDrawer("top", false)} href="/careers">
                Careers
              </Button>*/}
              <Button onClick={toggleDrawer("top", false)} href="/#contact-us">
                Contact Us
              </Button>
            </Drawer>
          </MobileView>
        </Toolbar>
      </AppBar>
    </div>
  );
}

Nav.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Nav);
