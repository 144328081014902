import React from "react";

import { isMobile } from "react-device-detect";

import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";

import Nav from "./landingPage/Nav.js";
import Footer from "./landingPage/Footer.js";
import PressCard from "./components/PressCard.js";

const useStyles = makeStyles((theme) => ({
  page: {
    minHeight: "40vh",
  },
  rootGrid: {
    paddingTop: "15px",
    paddingBottom: "15px",
  },
}));

function PressPage(props) {
  const classes = useStyles();
  var values = {};
  values.pad = {};
  if (isMobile) {
    values.direction = "column";
    values.xs = 12;
    values.pad.side = false;
    values.pad.inner = 12;
  } else {
    values.direction = "column";
    values.xs = 12;
    values.pad.side = 2;
    values.pad.inner = 12;
  }

  return (
    <div>
      <Nav></Nav>
      <Container maxWidth="lg">
        <Typography variant="h2" style={{ fontSize: "3rem" }}>
          Press
        </Typography>
      </Container>
      <br />
      <Container maxWidth="lg" className={classes.page}>
        <Grid
          container
          className={classes.rootGrid}
          spacing={1}
          alignItems="stretch"
          direction={values.direction}
        >
          <PressCard
            source="South Eastern Business Interviews"
            title="Innovators Pivotal Protection"
            date="April/May 2022"
            content="&#8220;Securium, University of Surrey's spin-off, is now supported by Surrey Research Park to stay true to its mission of creating safer and stronger communities by safeguarding the most vulnerable online.&#8221;"
            target="https://edition.pagesuite-professional.co.uk/html5/reader/production/default.aspx?pubname=&edid=53d6a78d-fdb9-4c50-832d-7f0838620324&pnum=18"
          />

          <PressCard
            source="DIGIT News"
            title="EIE Investor Readiness Programme 2022 Announces 36-strong Cohort"
            date="14 March 2022"
            content="&#8220;Supported by the Scottish Government, the lion's share of the EIE Investor Readiness Programme's 2022 cohort is made up of home-grown companies.&#8221;"
            target="https://www.digit.fyi/scots-majority-makes-up-high-growth-tech-companies-selected-for-eie-investor-readiness-programme/"
          />
          <PressCard
            source="Surrey Research Park"
            title="Surrey Female CEO Wins Prestigious Women in Innovation Award"
            date="8 March 2022"
            content="&#8220;In celebration of International Women's Day Dr Anna Vartapetiance, CEO and Co-Founder of Securium an advanced cyber-intelligence company and member of SETsquared Surrey, has been recognised by Innovate UK as a winner of its UK Women in Innovation Awards.&#8221;"
            target="https://surrey-research-park.com/surrey-female-ceo-wins-prestigious-women-in-innovation-award/"
          />
          <PressCard
            source="Innovate UK"
            title="Women in Innovation 2021/2022"
            date="2021/2022"
            content="&#8220;As the UK's national innovation agency, it is our mission to inspire, involve and invest in business innovation. Following a record-breaking number of applications, I'm delighted to introduce you to the winners of Innovate UK's Women in Innovation awards.&#8221;"
            target="https://ktn-uk.org/wp-content/uploads/2022/03/Women-in-Innovation-awards-brochure-2022_final.pdf"
          />

          <PressCard
            source="Best Startup"
            title="26 Guildford Internet Startups to Watch in 2021"
            date="June 2021"
            content="&#8220;This article showcases our top picks for the best Guildford based Internet companies.&#8221;"
            target="https://www.beststartup.co.uk/26-guildford-internet-startups-to-watch-in-2021/"
          />
          <PressCard
            source="University of Surrey"
            title="Guildford Innovation Awards 2020"
            date="17 March 2021"
            content="&#8220;In December 2020, the Guildford Innovation Awards, organised by Guildford Borough Council, Surrey Research Park and the University of Surrey took place.&#8221;"
            target="https://www.surrey.ac.uk/news/guildford-innovation-awards-2020"
          />

          <PressCard
            source="Department for Digital, Culture, Media and Sport"
            title="New report reveals UK as world leader in online safety innovation"
            date="27 May 2020"
            content="&#8220;The UK's rapidly-growing safety tech sector is helping make the online world safer for millions of people, a report published today shows.&#8221;"
            target="https://www.gov.uk/government/news/new-report-reveals-uk-as-world-leader-in-online-safety-innovation"
          />

          <PressCard
            source="Platinum Media Group"
            title="Surrey Business Awards 2019: The winners!"
            date="9 December 2019"
            content="&#8220;The annual Surrey Business Awards took place on November 21st at Epsom Downs Racecourse in front of 400 leading business people from across the region.&#8221;"
            target="https://www.platinummediagroup.co.uk/surrey-chambers-of-commerce-magazine/2019/12/surrey-business-awards-2019-the-winners/"
          />

          <PressCard
            source="The Telegraph"
            title="Paedophiles could be caught in act of grooming online by new technology"
            date="21 May 2019"
            content="&#8220;Technology that could detect paedophiles on social media by the language they use to groom children is being developed in a £550,000 Home Office project.&#8221;"
            target="http://bit.ly/2VYqmsb"
          />
        </Grid>
      </Container>
      <Footer></Footer>
    </div>
  );
}

export default PressPage;
