import React from "react";

import Nav from "./landingPage/Nav.js";
import Home from "./landingPage/Home.js";
import Products from "./landingPage/Products.js";
import AboutUs from "./landingPage/AboutUs.js";
import News from "./landingPage/News.js";
import Footer from "./landingPage/Footer.js";

function HomePage(props) {
  return (
    <>
      <Home>
        <Nav></Nav>
      </Home>
      <AboutUs></AboutUs>
      <Products></Products>
      {/*<News></News>*/}
      <Footer></Footer>
    </>
  );
}

export default HomePage;
