import React from "react";

import { isMobile } from "react-device-detect";

import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";

import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";

const styles = (theme) => ({
  card: {
    height: "100%",
  },
  cardHead: {
    paddingLeft: "15px",
    paddingTop: "20px",
    paddingBottom: "20px",
    fontSize: "1vh",
  },
  title: {
    fontSize: "1.7vh",
    marginBottom: "0",
    marginTop: "0.5vh",
  },
  pos: {
    marginBottom: 10,
    fontSize: "1.3vh",
  },
  cardTitle: {
    fontSize: "2.4vh",
  },
  cardBody: {
    fontSize: "1.7vh",
  },
  button: {
    color: "#33cbcc",
    paddingTop: "0px",
    fontSize: "1.7vh",
  },
  cardActions: {
    paddingTop: "0px",
    paddingBottom: "2px",
    paddingLeft: "10px",
  },
  cardContent: {
    paddingBottom: "2px",
    paddingTop: "2px",
  },
});

function PressCard(props) {
  const { classes } = props;
  var values = {};
  values.pad = {};
  if (isMobile) {
    values.direction = "column";
    values.xs = 12;
    values.pad.side = false;
    values.pad.inner = 12;
  } else {
    values.direction = "column";
    values.xs = 12;
    values.pad.side = 2;
    values.pad.inner = 12;
  }

  return (
    <Grid item xs={values.xs}>
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <Typography
            className={classes.title}
            color="textSecondary"
            gutterBottom
          >
            {props.source}
          </Typography>
          <Typography variant="h6" component="h3" className={classes.cardTitle}>
            {props.title}
          </Typography>
          <Typography
            variant="body2"
            className={classes.pos}
            color="textSecondary"
          >
            {props.date}
          </Typography>
          <Typography
            variant="body2"
            component="p"
            className={classes.cardBody}
          >
            {props.content}
          </Typography>
        </CardContent>
        <CardActions className={classes.cardActions}>
          <Button
            size="small"
            href={props.target}
            target="_blank"
            className={classes.button}
          >
            Read More
          </Button>
        </CardActions>
      </Card>
    </Grid>
  );
}

export default withStyles(styles)(PressCard);
