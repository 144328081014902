import React, { useState } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";

// import { createMuiTheme } from '@material-ui/core/styles';
// import { ThemeProvider } from '@material-ui/core/styles';

import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

import axios from "axios";
import { isMobile } from "react-device-detect";

// const outerTheme = createMuiTheme({
//   palette: {
//     secondary: {
//       main: 'rgba(255, 255, 255, 0.7)',
//     },
//   },
// });

const styles = {
  container: {
    display: "flex",
    flexWrap: "wrap",
    color: "#ffffff",
  },
  textField: {
    width: "48%",
    marginLeft: "1%",
    marginRight: "1%",
    marginTop: "1%",
    color: "rgba(255, 255, 255, 0.7)",
    borderColor: "rgba(255, 255, 255, 0.7)",
    "&:hover": {
      borderColor: "rgba(255, 255, 255, 0.7)",
    },
  },
  messageField: {
    marginLeft: "1%",
    marginRight: "1%",
    marginTop: "1%",
    marginBottom: "1%",
    width: "98%",
  },
  button: {
    marginLeft: "1%",
  },
  mailSent: {
    marginLeft: "1%",
    color: "black",
  },
  mobileH: {
    textAlign: "center",
    width: "100%",
  },
  headerGrid: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    marginBottom: "2vh",
  },
};

function ContactUs(props) {
  const { classes } = props;

  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [company, setcompany] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [mailSent, setMailSent] = useState(false);
  const [error, setError] = useState(false);

  function handleSubmit(event) {
    event.preventDefault();
    const API_PATH =
      "https://dlutaxlcyl.execute-api.eu-west-2.amazonaws.com/default/email-forwarder";

    axios({
      method: "POST",
      url: `${API_PATH}`,
      mode: "no-cors",
      headers: {
        "content-type": "application/json",
      },
      data: {
        name,
        surname,
        company,
        email,
        message,
      },
    })
      .then(() => setMailSent(true))
      .catch((error) => setError(error.message));
  }

  return (
    <div id="contact-us" className={classes.contactus}>
      <form
        onSubmit={handleSubmit}
        className={classes.container}
        autoComplete="off"
      >
        <Grid container className={classes.headerGrid}>
          <Grid item xs={12}>
            <Typography
              variant="h3"
              style={{ fontSize: "3.8vh", marginLeft: "1%", marginRight: "1%" }}
              className={`banner ${isMobile ? classes.mobileH : classes.h}`}
            >
              <b>Contact Us</b>
            </Typography>
          </Grid>
        </Grid>
        <Grid container direction="column">
          <Grid item>
            <TextField
              required
              id="outlined-name"
              label="Name"
              className={classes.textField}
              value={name}
              onChange={(e) => setName(e.target.value)}
              margin="normal"
              variant="outlined"
            />

            <TextField
              required
              id="outlined-surname"
              label="Surname"
              className={classes.textField}
              value={surname}
              onChange={(e) => setSurname(e.target.value)}
              margin="normal"
              variant="outlined"
            />

            <TextField
              id="outlined-company"
              label="Company"
              className={classes.textField}
              value={company}
              onChange={(e) => setcompany(e.target.value)}
              margin="normal"
              variant="outlined"
            />

            <TextField
              required
              id="outlined-email-input"
              label="Email"
              className={classes.textField}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              type="email"
              name="email"
              autoComplete="email"
              margin="normal"
              variant="outlined"
            />
          </Grid>
          <Grid item>
            <TextField
              required
              id="message"
              label="Message"
              multiline
              rowsMax="8"
              rows="8"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              className={classes.messageField}
              margin="normal"
              variant="outlined"
              inputProps={{ minLength: 50 }}
              helperText={
                `You have ${message.length} characters. ` +
                (message.length < 50
                  ? `Minimum character length of 50 are required.`
                  : ``)
              }
            />
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              disabled={mailSent}
              className={classes.button}
              type="submit"
              value="Submit"
            >
              Submit
            </Button>
            <div className={classes.mailSent}>
              {mailSent && <div>Thank you for contacting us.</div>}
            </div>
          </Grid>
        </Grid>
      </form>
    </div>
  );
}

ContactUs.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ContactUs);
