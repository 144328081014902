import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";

// images
import SecuriumSafeguard from "../assets/images/SecuriumSafeguard.jpg";
import SecuriumDiscover from "../assets/images/SecuriumDiscover.jpg";

import { isMobile } from "react-device-detect";

const styles = {
  products: {
    flexGrow: 1,
    paddingTop: "0vh",
    width: "90%",
    marginLeft: "auto",
    marginRight: "auto",
  },
  paper: {
    boxShadow: 3,
  },
  rootGrid: {
    padding: "2vh",
  },
  heading: {
    textAlign: "center",
    paddingTop: "2vh",
    paddingBottom: "0.5vh",
  },
  p: {
    fontSize: "1rem",
    marginBottom: "0.5vh",
    textAlign: "justify",
  },
  h: {
    fontSize: "1.3rem",
    marginBottom: "1vh",
    color: "#33cbcc",
  },
  image: {
    width: "90%",
    position: "absolute",
  },
  media: {
    width: "90%",
    justify: "center",
    margin: "auto",
    display: "block",
    minHeight: "20%",
    maxHeight: "25%",
  },
  cardHead: {
    textAlign: "center",
    paddingTop: "20px",
    paddingBottom: "20px",
    fontSize: "2rem",
  },
  cardImage: {
    justifyContent: "center",
    marginTop: "auto",
    marginBottom: "auto",
  },
  cardText: {
    justifyContent: "center",
    marginTop: "auto",
    marginBottom: "auto",
  },
  card: {
    padding: "1vh",
    paddingLeft: "1.2vw",
    paddingRight: "1.2vw",
    "&:last-child": {
      paddingBottom: "1vh",
    },
  },
  cardItem: {
    marginBottom: "1vh",
  },
  imgContainer: {
    display: "flex",
    justifyContent: "center",
    height: "80%",
    marginTop: "1vh",
    marginBottom: "1vh",
    // marginLeft: "1vw",
    // marginRight: "1vw",
  },
};

function Products(props) {
  const { classes } = props;
  var values = {};
  values.pad = {};
  if (isMobile) {
    values.direction = "column";
    values.card1Direction = "column-reverse";
    values.card2Direction = "column";
    values.xs = 12;
    values.imageSize = 12;
    values.textSize = 12;
    values.pad.side = false;
    values.pad.inner = 12;
  } else {
    values.direction = "column";
    values.card1Direction = "row";
    values.card2Direction = "row";
    values.xs = 12;
    values.imageSize = 4;
    values.textSize = 8;
    values.pad.inner = 12;
  }

  return (
    <div id="products" className={classes.products}>
      <div className={classes.heading}>
        <Typography variant="h3" style={{ fontSize: "3.8vh" }}>
          <b>Products</b>
        </Typography>
      </div>
      <Grid
        container
        className={classes.rootGrid}
        direction={values.direction}
        alignItems={"flex-start"}
      >
        <Grid item xs={values.xs} className={classes.cardItem}>
          <Card className={classes.paper} elevation={0}>
            <Grid
              container
              className={classes.cardGrid}
              direction={values.card1Direction}
            >
              <Grid item className={classes.cardImage} xs={values.imageSize}>
                <div className={classes.imgContainer}>
                  <img
                    className={classes.media}
                    src={SecuriumSafeguard}
                    alt="Securium-Safeguard"
                  ></img>
                </div>
              </Grid>

              <Grid item className={classes.cardText} xs={values.textSize}>
                <CardContent className={classes.card}>
                  <Typography className={classes.h}>
                    <b>Securium-Safeguard</b>
                  </Typography>
                  <Typography variant="body1" className={classes.p}>
                    <b>Securium-Safeguard</b>, the first of its kind, uses AI
                    that is grounded by proven psychological theories to
                    identify activities that can lead to online grooming and
                    exploitation, looking at the presence of behaviours such as
                    daring, and patterns of request/reward/repeat. The tools
                    highlight dangerous and illegal communications whether in
                    chat rooms or gaming platforms.
                  </Typography>
                  <Typography variant="body1" className={classes.p}>
                    Our software provides accurate real-time detection and
                    prevention for various organisations - such as for Law
                    Enforcement, Social platforms (chat and gaming) and
                    enterprise/school monitoring – to help them protect both
                    children, the well-being of moderators and analysis, and
                    brand reputation.
                  </Typography>
                  <Typography variant="body1" className={classes.p}>
                    <b>Securium-Safeguard</b> can also be applied to detect and
                    prevent catfishing, radicalisation, and other conversational
                    manipulations.
                  </Typography>
                </CardContent>
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid item xs={values.xs}>
          <Card className={classes.paper} elevation={0}>
            <Grid
              container
              className={classes.cardGrid}
              direction={values.card2Direction}
            >
              <Grid item className={classes.cardText} xs={values.textSize}>
                <CardContent className={classes.card}>
                  <Typography className={classes.h}>
                    <b>Securium-Discover</b>
                  </Typography>
                  <Typography component="p" className={classes.p}>
                    <b>Securium-Discover</b> helps organisations in the hunt for
                    high value targeted intelligence online within the
                    continually growing information volume. Using unique
                    open-source intelligence gathering methodology,
                    Securium-Discover reliably identifies and maps web locations
                    where materials of interest have been published in whole or
                    in parts. It identifies shared passages of text that create
                    tacit links between otherwise unlinked web pages and
                    websites.
                  </Typography>
                  <Typography component="p" className={classes.p}>
                    <b>Securium-Discover</b> tracks online dissemination of
                    harmful content, whether for national security (such as
                    terrorist propaganda, to facilitate its removal) or
                    reputation/brand management purposes.
                  </Typography>
                  <Typography component="p" className={classes.p}>
                    <b>Securium-Discover</b> helps organisations – such as fake
                    news and fact-checking companies, enterprises, news
                    publishers, campaign managers - to understand the
                    distribution/dissemination of large volume of content
                    easily, quickly and effectively.
                  </Typography>
                </CardContent>
              </Grid>
              <Grid item className={classes.cardImage} xs={values.imageSize}>
                <div className={classes.imgContainer}>
                  <img
                    className={classes.media}
                    src={SecuriumDiscover}
                    alt="Securium-Discover"
                  ></img>
                </div>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}

Products.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Products);
