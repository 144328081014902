import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";

import PressCard from "../components/PressCard.js";

import { isMobile } from "react-device-detect";

import Break from "./Break.js";

const styles = {
  press: {
    flexGrow: 1,
    paddingTop: "0vh",
    overflow: "hidden",
  },
  pressGrid: {
    width: "90%",
    margin: "auto",
  },
  rootGrid: {
    paddingTop: "15px",
    paddingBottom: "15px",
  },
  heading: {
    textAlign: "center",
    marginTop: "2vh",
    marginBottom: "0px",
  },
  p: {
    fontSize: "1vh",
    marginBottom: "9px",
  },
  h: {
    marginBottom: "20px",
  },
  card: {
    height: "100%",
  },
  cardHead: {
    paddingLeft: "15px",
    paddingTop: "20px",
    paddingBottom: "20px",
    fontSize: "1vh",
  },
  title: {
    fontSize: "1.7vh",
    marginBottom: "0",
    marginTop: "0.5vh",
  },
  pos: {
    marginBottom: 10,
    fontSize: "1.3vh",
  },
  cardTitle: {
    fontSize: "2.4vh",
  },
  cardBody: {
    fontSize: "1.7vh",
  },
  button: {
    color: "#33cbcc",
    paddingTop: "0px",
    fontSize: "1.7vh",
  },
  redirectButton: {
    color: "#33cbcc",
    paddingTop: "0px",
    textAlign: "center",
    fontSize: "2.5vh",
    marginRight: "auto",
    marginLeft: "auto",
  },
  cardActions: {
    paddingTop: "0px",
    paddingBottom: "2px",
    paddingLeft: "10px",
  },
  cardContent: {
    paddingBottom: "2px",
    paddingTop: "2px",
  },
};

function News(props) {
  const { classes } = props;
  var values = {};
  values.pad = {};
  if (isMobile) {
    values.direction = "column";
    values.xs = 12;
    values.pad.side = false;
    values.pad.inner = 12;
  } else {
    values.direction = "column";
    values.xs = 12;
    values.pad.side = 2;
    values.pad.inner = 12;
  }

  return (
    <div id="press" className={classes.press}>
      <Break></Break>
      <div className={classes.heading}>
        <Typography variant="h3" style={{ fontSize: "3.8vh" }}>
          <b>Press</b>
        </Typography>
      </div>
      <Grid container className={classes.pressGrid}>
        <Grid item xs={values.pad.side}></Grid>
        <Grid item xs={values.pad.inner}>
          <Grid
            container
            className={classes.rootGrid}
            spacing={1}
            alignItems="stretch"
            direction={values.direction}
          >
            <PressCard
              source="South Eastern Business Interviews"
              title="Innovators Pivotal Protection"
              date="April/May 2022"
              content="&#8220;Securium, University of Surrey's spin-off, is now supported by Surrey Research Park to stay true to its mission of creating safer and stronger communities by safeguarding the most vulnerable online.&#8221;"
              target="https://edition.pagesuite-professional.co.uk/html5/reader/production/default.aspx?pubname=&edid=53d6a78d-fdb9-4c50-832d-7f0838620324&pnum=18"
            />

            <PressCard
              source="DIGIT News"
              title="EIE Investor Readiness Programme 2022 Announces 36-strong Cohort"
              date="14 March 2022"
              content="&#8220;Supported by the Scottish Government, the lion's share of the EIE Investor Readiness Programme's 2022 cohort is made up of home-grown companies.&#8221;"
              target="https://www.digit.fyi/scots-majority-makes-up-high-growth-tech-companies-selected-for-eie-investor-readiness-programme/"
            />
            <PressCard
              source="Surrey Research Park"
              title="Surrey Female CEO Wins Prestigious Women in Innovation Award"
              date="8 March 2022"
              content="&#8220;In celebration of International Women's Day Dr Anna Vartapetiance, CEO and Co-Founder of Securium an advanced cyber-intelligence company and member of SETsquared Surrey, has been recognised by Innovate UK as a winner of its UK Women in Innovation Awards.&#8221;"
              target="https://surrey-research-park.com/surrey-female-ceo-wins-prestigious-women-in-innovation-award/"
            />
            <PressCard
              source="Innovate UK"
              title="Women in Innovation 2021/2022"
              date="2021/2022"
              content="&#8220;As the UK's national innovation agency, it is our mission to inspire, involve and invest in business innovation. Following a record-breaking number of applications, I'm delighted to introduce you to the winners of Innovate UK's Women in Innovation awards.&#8221;"
              target="https://ktn-uk.org/wp-content/uploads/2022/03/Women-in-Innovation-awards-brochure-2022_final.pdf"
            />
          </Grid>
        </Grid>
        <Button size="small" href="/press" className={classes.redirectButton}>
          Read More
        </Button>
      </Grid>
    </div>
  );
}

News.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(News);
